import { SessionPermissionType } from 'src/app/core/sessions/sessions.types';
import { Route } from './sidebar/metadata';

export let ROUTES: Route[] = [

  // --------------------------------- MARK:MASTER EVENT ---------------------------------
  {
    title: 'Master Event',
    permissions: false,
    slug: 'events',
    icon: 'assets/media/icon-calendar-white.png',
    iconType: 'image',
    submenu: [
      {
        path: 'master-event/list',
        title: 'List Event',
        permissions: '',
        slug: 'events',
        iconType: '',
        submenu: [],
      },
    ],
  },

  // --------------------------------- MARK:MASTER DATA ---------------------------------
  {
    title: 'Master Data',
    permissions: false,
    slug: 'master-data',
    icon: 'assets/media/icon-masterdata.png',
    iconType: 'image',
    submenu: [
      {
        path: 'master-booth',
        title: 'Master Booth',
        permissions: '',
        slug: 'booths',
        iconType: '',
        submenu: [],
      },
      {
        path: 'master-menu',
        title: 'Master Menu',
        permissions: '',
        slug: 'menus',
        iconType: '',
        submenu: [],
      },
      {
        path: 'master-user',
        title: 'Master User',
        permissions: '',
        slug: 'users',
        iconType: '',
        submenu: [],
      },
    ],
  },
];
