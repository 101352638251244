import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Paginator, Paginator_m } from 'src/app/services/helper.service';

@Component({
  selector: 'app-paginator-bottom',
  templateUrl: './paginator-bottom.component.html',
  styleUrls: ['./paginator-bottom.component.scss']
})
export class PaginatorBottomComponent implements OnInit {

  @Input() data!:Paginator_m;
  @Input() customOpt!:any[];
  @Input() showPaginator!:boolean;
  @Output() onValueChange = new EventEmitter();

  first = 0;
  options = [10,20,50,100];
  constructor() { }

  ngOnInit() {
    if(this.data){
      this.first = (this.data.page - 1) * this.data.limit;
    } else {
      this.data = Object.assign({}, Paginator);
    }

    if (this.customOpt) {
      this.options = this.customOpt;
    }
  }

  onPageChange(event:any){
    this.first = event.page * event.rows;
    this.data.limit = event.rows;
    this.data.page = event.page;
    this.onValueChange.emit(this.data);
  }

  onLimitChange(event:any){
    this.data.limit = event.value ?? '';
    this.data.page = 0;
    this.onValueChange.emit(this.data);
  }

}
